@use '@angular/material' as mat;
@include mat.core();

$myapp-theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette, A400, A100, A700);
$myapp-theme-accent: mat.m2-define-palette(mat.$m2-indigo-palette);
$myapp-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
$angular-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $myapp-theme-primary,
      accent: $myapp-theme-accent,
      warn: $myapp-theme-warn,
    ),
  )
);

$myapp-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $myapp-theme-primary,
      accent: $myapp-theme-accent,
      warn: $myapp-theme-warn,
    ),
  )
);

@include mat.all-component-themes($myapp-theme);
@import '_constants_';
@import '_colors_';
@import '_animations_';
* {
  box-sizing: border-box;
  --icon-size: 24px;
  --icon-svg-size: 24px;
  --icon-placeholder-bg: #ccc;
}
html,
body {
  height: 100%;
}
body {
  display: block;
  margin: 0;
  padding: 0;
  font: 400 14px 'Nunito Sans';
  font-family: 'Nunito Sans', sans-serif;
  line-height: 19.1px;
  height: 100%;
}

@media (max-width: 575px) {
  body,
  html {
    font-size: 13px;
  }
}

@media (max-width: 340px) {
  body,
  html {
    font-size: 11px;
  }
}

.darkMode {
  @include mat.all-component-colors($angular-dark-theme);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
textarea {
  font-family: 'Nunito';
  font-family: 'Nunito Sans', sans-serif;

  margin: 0 !important;
  font-weight: 400;
}

::-webkit-input-placeholder {
  color: #757575;
}

::-webkit-scrollbar {
  display: none;
}

:focus {
  outline: 0;
}

::-webkit-file-upload-button {
  background: transparent;
  border-radius: 5px;
  color: black;
  padding: 6px 12px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  box-shadow: 0 0 0 30px black inset !important;
}

.ios {
  input:-webkit-autofill,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:hover {
    -webkit-text-fill-color: black !important;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.75 !important;
  }

  &.clear-backdrop {
    background: #000;
    opacity: 0.15 !important;
  }
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.emoji {
  font-size: 1.4em;
  vertical-align: middle;
  line-height: 1.1;
  padding: 0 0.08em 0 0.05em;
}

textarea {
  resize: none;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  -webkit-animation:
    90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  -webkit-animation:
    210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}
