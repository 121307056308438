body {
  --lateral-left-width: 280px;
  --lateral-right-width: 400px;
  --header-h: 60px;
  --header-double-row: 100px;

  --header-wallet-h: 36px;
  --header_h_small: 54px;
  --header_office_h: 56px;
  --balance_h: 42px;
  --balance_h_small: 46px;
  --header_office_h: 56px;
  --menuBar_h: 60px;
  --menuBar_h_small: 40px;
  --submenu_h: 2.6em;
  --submenu-app-h: 40px;
  --currencies_menu_h: 38px;
  --header_pdf_viewer_h: 42px;
  --bet-ticket_h: 264px;
  --bet-ticket_w: 400px;
  --menu-height: 70px;
  --card-radius: 5px;
  --card-bottom_h: 36px;
  --short-cut_w: 350px;
  --short-cut_h: 220px;
  --media_mobile: 575px;
  --input_h: 3.5em;
  --input_padding: 0.15em;
  --avatar-chip-width: 140px;
  --avatar-chip-width-200: 200px;
  --avatar-chip-height: 180px;
  --cc-form-w: 320px;
  --frame_width: 600px;
  --header-buttons-h: 40px;

  --display-width: 100vw;
  --left-aside_close_w: 0;
  --centered-content-margin: 2em;
  --centered-content-w: min(calc(var(--display-width) - 2 * var(--centered-content-margin)), 1024px);
  --button-h: 40px;
  --betting-form-w: 600px;

  --animal_cheap_big_w: 160px;
  --animal_cheap_medium_w: 96px;
  --animal_cheap_small_w: 96px;

  --animal_cheap_big_h: 110px;
  --animal_cheap_medium_h: 120px;
  --animal_cheap_small_h: 62px;

  --deposit-crypto-row: 400px;
  --chip-h: 60px;
  --chip-w: 310px;
  --centered-w: 568px;

  --asside-right-w: 400px;
  --asside-left-w: 320px;

  --game-stage-ratio: 0.6;
  --game-ratio: 0.65625;
  --game-w: 220px;
  --game-h: calc(var(--game-w) * var(--game-ratio));
  --game-wrapper-h: calc(var(--game-h) + 44px);
  --game-gap: 0.65em;
  --border-radius-small: 8px;
}

@media (max-width: 700px) {
  body {
    --centered-content-w: calc(var(--display-width) - 2 * var(--centered-content-margin));
  }
}

@media (max-width: 600px) {
  body {
    --frame_width: 100vw;
    --centered-w: 100vw;
    --betting-form-w: 98vw;
    --lateral-right-width: 100vw;
    --game-w: 190px;
  }
}

@media (max-width: 400px) {
  body {
    --deposit-crypto-row: 340px;
    --asside-right-w: 100vw;
    --game-w: 170px;
  }
}

@media (max-height: 850px) {
  body {
    --game-stage-ratio: 0.5;
  }
}

@media (max-height: 750px) {
  body {
    --game-stage-ratio: 0.4;
  }
}

@media (max-height: 650px) {
  body {
    --game-stage-ratio: 0.35;
  }
}
