@keyframes x-spin {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes z-spin {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes blinkingText {
  0% {
    color: var(--grey-900);
  }

  49% {
    color: var(--grey-900);
  }

  60% {
    color: var(--accent-color);
  }

  99% {
    color: var(--accent-color);
  }

  100% {
    color: var(--grey-900);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(0.95);
  }
}
@keyframes _opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes scale-animation {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes reverse-scale-animation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.1);
  }
}
@keyframes animalsOpen {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scale-animation-btn {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
@keyframes scale-animation-btn-reverse {
  from {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
}
@keyframes scale-animation-number {
  from {
    font-weight: normal;
    color: var(--grey-700);
  }

  to {
    font-weight: bold;
    color: green;
  }
}
@keyframes blinking {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
